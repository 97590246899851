import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRules(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/rule', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRule(ctx, ruleId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/rule/${ruleId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveRule(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/rule', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRule(ctx, ruleId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/rule/${ruleId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRulesOverspillPremiumTypes(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/rule/overspill-premium-types', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

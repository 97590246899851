import axios from '@axios'

export default {
  namespaced: true,
  state: {
    referencePeriods: [],
  },
  getters: {
    GET_REFERENCE_PERIODS(state) {
      return state.referencePeriods
    },
  },
  mutations: {
    UPDATE_REFERENCE_PERIODS(state, referencePeriods) {
      state.referencePeriods = referencePeriods
    },
    UPDATE_REFERENCE_PERIOD(state, referencePeriod) {
      state.referencePeriods.push(referencePeriod)
    },
  },
  actions: {
    fetchReferencePeriods(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/reference-period/search', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReferencePeriod(ctx, periodId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/reference-period/${periodId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveReferencePeriod(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/reference-period', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteReferencePeriod(ctx, periodId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/reference-period/${periodId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import dashboard from './routes/dashboard'
import rules from './routes/rules'
import seances from './routes/seances'
import metadata from './routes/metadata'
import debuggerR from './routes/debugger'

let timeOutObj

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/home',
      name: 'app.home',
      component: () => import('@/views/pages/dashboard/Analytics.vue'),
      meta: {
        pageTitle: 'Dashboard',
        authRequired: true,
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    ...dashboard,
    ...rules,
    ...seances,
    ...metadata,
    ...debuggerR,
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/under-maintenance',
      name: 'under-maintenance',
      component: () => import('@/views/pages/miscellaneous/UnderMaintenance'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'login',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.meta.authRequired && !store.getters['session/isAuthenticated']) next({ name: 'auth-login' })
  else if (to.meta.redirectIfLoggedIn && store.getters['session/isAuthenticated']) next({ name: 'app.home' })
  else next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

// eslint-disable-next-line no-unused-vars
function startTimer() {
  timeOutObj = setTimeout(() => {
    try {
      store.dispatch('session/logout').then(value => {
        // Remove userData from localStorage
        localStorage.removeItem('userData')
        router.push({ name: 'auth-login' })
        return value
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }, 3540000) // will expire after sixty minutes - 3600000 - 3540000 - OK
}

function resetTimer() {
  window.clearTimeout(timeOutObj)
  startTimer()
}

startTimer()

document.addEventListener('mousemove', resetTimer, false)
document.addEventListener('mousedown', resetTimer, false)
document.addEventListener('keypress', resetTimer, false)
document.addEventListener('touchmove', resetTimer, false)
document.addEventListener('onscroll', resetTimer, false)

export default router

import axios from '@axios'

export default {
  namespaced: true,
  state: {
    premiumTypes: [],
  },
  getters: {
    GET_PREMIUM_TYPES(state) {
      return state.premiumTypes
    },
  },
  mutations: {
    UPDATE_PREMIUM_TYPES(state, premiumTypes) {
      state.premiumTypes = premiumTypes
    },
    UPDATE_PREMIUM_TYPE(state, premiumType) {
      state.premiumTypes.push(premiumType)
    },
  },
  actions: {
    fetchPremiumTypes(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/rule/premium-type/search', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPremiumType(ctx, premiumType) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/rule/premium-type/${premiumType}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    savePremiumType(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/rule/premium-type', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePremiumType(ctx, premiumType) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/rule/premium-type/${premiumType}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

import axios from '@axios'

export default {
  namespaced: true,
  state: {
    requestLog: [],
  },
  getters: {
    GET_REQUEST_LOG(state) {
      return state.requestLog
    },
  },
  mutations: {},
  actions: {
    fetchRequestLogs(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/failed-request-log/search', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRequestLog(ctx, reqId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/failed-request-log/${reqId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

import axios from '@/libs/axios'

export const AUTH_REQUEST = 'auth_request'
export const AUTH_SUCCESS = 'auth_success'
export const AUTH_ERROR = 'auth_error'

export const AUTH_STATE_REQUEST = 'auth_state_request'
export const AUTH_STATE_SUCCESS = 'auth_state_success'
export const AUTH_STATE_ERROR = 'auth_state_error'

export const CSRF_REQUEST = 'csrf_request'
export const CSRF_SUCCESS = 'csrf_request_success'
export const CSRF_ERROR = 'csrf_request_error'

export const LOGOUT_SUCCESS = 'logout_request_success'

export default {
  namespaced: true,
  state: {
    initialized: false,
    auth: null,
    csrf: null,
  },
  getters: {
    currentUserName: state => (state.auth ? state.auth.name : null),
    currentUser: state => state.auth,
    isAuthenticated: state => (state.auth ? state.auth : false),
    isInitialized: state => state.initialized,
  },
  mutations: {
    [AUTH_REQUEST](state) {
      state.auth = null
    },
    [AUTH_SUCCESS](state, value) {
      state.auth = value
      localStorage.setItem('loggedIn', 'true')
      localStorage.setItem('userData', JSON.stringify(value))
    },
    [AUTH_ERROR](state) {
      state.auth = null
    },
    [LOGOUT_SUCCESS](state) {
      state.auth = null
    },
    [AUTH_STATE_REQUEST](state) {
      state.auth = null
    },
    [AUTH_STATE_SUCCESS](state, value) {
      state.auth = value
      state.initialized = true
      localStorage.setItem('loggedIn', 'true')
      localStorage.setItem('userData', JSON.stringify(value))
    },
    [AUTH_STATE_ERROR](state) {
      state.auth = null
    },
    [CSRF_REQUEST](state) {
      state.csrf = ''
    },
    [CSRF_SUCCESS](state, value) {
      state.csrf = value
      axios.defaults.headers.post['x-token'] = value.token
    },
    [CSRF_ERROR](state) {
      state.csrf = ''
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit(AUTH_REQUEST)
        axios.post('/user/login', user)
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            commit(AUTH_ERROR)
            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post('/user/logout')
          .then(resp => {
            commit(LOGOUT_SUCCESS)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getAuthState({ commit }) {
      return new Promise((resolve, reject) => {
        commit(AUTH_STATE_REQUEST)
        axios.get('/me')
          .then(resp => {
            commit(AUTH_STATE_SUCCESS, resp.data.data)
            resolve(resp)
          })
          .catch(err => {
            commit(AUTH_STATE_ERROR)
            reject(err)
          })
      })
    },
    getCsrfToken({ commit }) {
      return new Promise((resolve, reject) => {
        commit(CSRF_REQUEST)
        axios.get('/csrf')
          .then(resp => {
            commit(CSRF_SUCCESS, resp.data.data)
            resolve(resp)
          })
          .catch(err => {
            commit(CSRF_ERROR)
            reject(err)
          })
      })
    },
  },
}

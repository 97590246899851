export default [
  {
    path: '/periods/search-list',
    name: 'periods.search-list',
    component: () => import('@/views/pages/reference-period/SearchList.vue'),
    meta: {
      pageTitle: 'Search',
      breadcrumb: [
        {
          text: 'Periods',
          active: true,
        },
      ],
    },
  },
  {
    path: '/premium-types/search-list',
    name: 'premiumtypes.search-list',
    component: () => import('@/views/pages/premium-types/SearchList.vue'),
    meta: {
      pageTitle: 'Search',
      breadcrumb: [
        {
          text: 'Premium Types',
          active: true,
        },
      ],
    },
  },
]

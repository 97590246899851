import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSeances(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/seance/search', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSeance(ctx, seanceId) {
      return new Promise((resolve, reject) => {
        axios
          .post('/seance/get', { id: seanceId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSeanceStatsBySource(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/seance/stats/source', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSeanceStatsByTotalCounts(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/seance/stats/total-counts', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSeancesActivity(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/seance/activity', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import rulesStoreModule from '@/views/pages/rules/rulesStoreModule'
import seancesStoreModule from '@/views/pages/seances/seancesStoreModule'
import loginUserStoreModule from '@/views/pages/authentication/loginUserStoreModule'
import referencePeriodStoreModule from '@/views/pages/reference-period/referencePeriodsStoreModule'
import premiumTypesStoreModule from '@/views/pages/premium-types/premiumTypesStoreModule'
import requestLogStoreModule from '@/views/pages/debugger/requestLogStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import session from './session'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    'clx-user-login': loginUserStoreModule,
    app,
    appConfig,
    verticalMenu,
    'premium-rules': rulesStoreModule,
    session,
    'seance-rules': seancesStoreModule,
    'rules-reference-periods': referencePeriodStoreModule,
    'rules-premium-types': premiumTypesStoreModule,
    'request-logs': requestLogStoreModule,
  },
  strict: process.env.DEV,
})

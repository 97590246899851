export default [
  {
    path: '/debugger/search-list',
    name: 'debugger.search-list',
    component: () => import('@/views/pages/debugger/SearchList.vue'),
    meta: {
      pageTitle: 'Search',
      breadcrumb: [
        {
          text: 'Debugger',
          active: true,
        },
      ],
    },
  },
]

import Vue from 'vue'

// axios
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

Vue.prototype.$http = axiosIns

axiosIns.interceptors.response.use(
  response => {
    if (response.data.data && response.data.data.redirect) {
      window.location.href = response.data.data.redirect
    }

    return Promise.resolve(response)
  },
  error => {
    let title = 'Application error'
    let variant = 'danger'
    let text = 'Unknown application error'

    if (error.response) {
      if (error.response.status === 302 || error.response.status === 301) {
        const [location] = error.response.getHeader('Location')
        window.location.href = location
      } else if (error.response.status === 412) {
        title = 'Security verification'
        variant = 'warning'
        text = error.response.data.detail
      } else if (error.response.status === 500) {
        text = error.response.data.detail
      }
      // client received an error response (5xx, 4xx)
    } else if (error.request) {
      text = error.message
      // eslint-disable-next-line no-console
      console.log(error)
    } else {
      text = error.message
      // eslint-disable-next-line no-console
      console.log(text)
    }

    Vue.$toast({
      component: ToastificationContent,
      position: 'bottom-right',
      props: {
        title,
        icon: 'CoffeeIcon',
        variant,
        text,
      },
    })

    return Promise.reject(error)
  },
)

export default axiosIns

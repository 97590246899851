export default [
  {
    path: '/seances/search-company',
    name: 'seances.search.company',
    component: () => import('@/views/pages/seances/SearchCompany.vue'),
    meta: {
      pageTitle: 'Company Search',
      breadcrumb: [
        {
          text: 'Company Search',
          active: true,
        },
      ],
    },
  },
  {
    path: '/seances/search-user',
    name: 'seances.search.user',
    component: () => import('@/views/pages/seances/SearchUser.vue'),
    meta: {
      pageTitle: 'User Search',
      breadcrumb: [
        {
          text: 'User Search',
          active: true,
        },
      ],
    },
  },
  {
    path: '/seances/search-list/:r/:q',
    name: 'seances.search-list',
    component: () => import('@/views/pages/seances/SearchList.vue'),
    meta: {
      pageTitle: 'Search',
      breadcrumb: [
        {
          text: 'Seances',
          active: true,
        },
      ],
    },
  },
  {
    path: '/seances/search-list',
    name: 'seances.search-list',
    component: () => import('@/views/pages/seances/SearchList.vue'),
    meta: {
      pageTitle: 'Search',
      breadcrumb: [
        {
          text: 'Seances',
          active: true,
        },
      ],
    },
  },
]

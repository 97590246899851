export default [
  {
    path: '/rules/search',
    name: 'rules.search',
    component: () => import('@/views/pages/rules/Search.vue'),
    meta: {
      pageTitle: 'Search',
      breadcrumb: [
        {
          text: 'Rules',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rules/search-list',
    name: 'rules.search-list',
    component: () => import('@/views/pages/rules/SearchList.vue'),
    meta: {
      pageTitle: 'Search',
      breadcrumb: [
        {
          text: 'Rules',
          active: true,
        },
      ],
    },
  },
]
